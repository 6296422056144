import Product from "./Product";

const Column = ({
  columnTitle,
  products,
  innerRef,
  provided,
  draggingOver,
}) => {
  const className = `${
    columnTitle === "Matching Area" && draggingOver
      ? "svItemsWrapper draggingOver matchingArea"
      : columnTitle === "Matching Area"
      ? "svItemsWrapper matchingArea"
      : columnTitle !== "Matching Area" && draggingOver
      ? "svItemsWrapper draggingOver"
      : "svItemsWrapper"
  }`;
  return (
    <div className={className} ref={innerRef}>
      <h4 className="title">{columnTitle}</h4>
      <div className="itemsWrapper">
        {products.length > 0
          ? products.map((item, index) => (
              <Product
                key={index}
                index={index}
                columnTitle={columnTitle}
                item={item}
              />
            ))
          : columnTitle === "Matching Area"
          ? ""
          : ""}
      </div>
      {provided.placeholder}
    </div>
  );
};

export default Column;
