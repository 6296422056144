import React, { Fragment, useEffect, useState } from "react";
import "../../assets/styles/items.css";
import dishImage from "../../assets/images/dish.png";
import axiosAuthInstance from "../../api/axiosAuthInstance";

export const Items = ({
  toggleItems,
  products,
  setItemNotes,
  handleSelectProduct,
}) => {
  const [itemId, setItemId] = useState(null);

  useEffect(() => {
    if (itemId) {
      fetchItemNotes(itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const fetchItemNotes = async (itemId) => {
    try {
      toggleItems();
      const response = await axiosAuthInstance.get(
        `/TblItemNote/getPage?userQuery=fkItemId=${itemId}`
      );
      const { rs } = response.data;
      setItemNotes(rs.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div
        className={`items-wrapper`}
        onClick={() => {
          toggleItems();
        }}
      ></div>
      <div
        className="items-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {products
          ? Object.values(products).map((item, i) => (
              <div
                className="item-wrapper"
                data-itemid={item.pkItemId}
                key={item.pkItemId}
                onClick={(e) => {
                  setItemId(item.pkItemId);
                  handleSelectProduct(item);
                }}
              >
                <div className="image-wrapper">
                  <img src={dishImage} alt="dish" />
                </div>
                <span className="itemname">
                  {item.itemNameLang2 ?? item.itemName}
                </span>
              </div>
            ))
          : ""}
      </div>
    </Fragment>
  );
};
