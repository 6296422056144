import React from "react";
import { Modal } from "./Modal";
import { useApi } from "../../customHooks/useApi";
import Dropdown from "react-bootstrap/Dropdown";
import axiosAuthInstance from "../../api/axiosAuthInstance";
import { useState } from "react";

export const HallTableWaiterModal = ({
  closeModal,
  setTable,
  setHall,
  setOrderType,
}) => {
  const [tables, setTables] = useState(null);
  const [currentHall, setCurrentHall] = useState(null);
  const [currentTable, setCurrentTable] = useState(null);
  const { data } = useApi(`/TblHall/getPage/?pageSize=100`);
  const halls = data?.rs?.data;

  const fetchHallTables = async (item) => {
    const response = await axiosAuthInstance.get(
      `Cashier/GetAvailableTables?userQuery=hall_id=${item.pkHallId}`
    );
    const { data } = response;
    setTables(data);
  };

  const handleHallClick = (item) => {
    setHall({
      hallId: item.pkHallId,
      hallName: item.hallName,
    });
    setCurrentHall(item);
    fetchHallTables(item);
  };

  const handleTableClick = (item) => {
    setTable({
      tableId: item.PK_Table_ID,
      tableName: item.TableName,
    });
    setCurrentTable(item);
  };

  const handleHallTableChange = () => {
    setOrderType({ id: 1, type: "dinein" });

    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="row mb-4">
        <div className="col-md-6 mb-4">
          <label htmlFor="room">Halls</label>

          {/* <InputLabel
                  sx={{
                    color: "#d14418",
                    fontWeight: "600",
                    marginBottom: "2rem !important",
                  }}
                  id="demo-simple-select-label"
                >
                  Room
                </InputLabel> */}
          {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
                // className="noHover"
                sx={{ border: "1px solid #d14418" }}
              >
                {hallsResponse.data
                  ? hallsResponse.data.map((item) => (
                      <MenuItem key={item.pkHallId} value={10}>
                        {item.hallName}
                      </MenuItem>
                    ))
                  : ""}
              </Select> */}

          <Dropdown>
            <Dropdown.Toggle
              style={{
                width: "100%",
                textAlign: "left",
                backgroundColor: "#d14418",
                border: "none",
                height: "50px",
              }}
              id="dropdown-basic"
            >
              Select Hall
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                margin: 0,
              }}
            >
              {halls
                ? halls.map((item) => (
                    <Dropdown.Item
                      onClick={() => handleHallClick(item)}
                      key={item.pkHallId}
                      style={{ height: "2.5rem" }}
                    >
                      {item.hallName}
                    </Dropdown.Item>
                  ))
                : ""}
            </Dropdown.Menu>
          </Dropdown>

          {currentHall ? (
            <span className="currenthall">
              Current Hall: <span>{currentHall.hallName}</span>
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <label htmlFor="room">Table</label>
          <Dropdown>
            <Dropdown.Toggle
              style={{
                width: "100%",
                textAlign: "left",
                backgroundColor: "#d14418",
                border: "none",
                height: "50px",
              }}
              id="dropdown-basic"
            >
              Select Table
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                margin: 0,
                height: "auto",
                maxHeight: "15rem",
                overflowY: "scroll",
              }}
            >
              {tables
                ? tables.map((item) => (
                    <Dropdown.Item
                      key={item.PK_Table_ID}
                      style={{
                        height: "2.5rem",
                      }}
                      onClick={() => handleTableClick(item)}
                    >
                      {item.TableName}
                    </Dropdown.Item>
                  ))
                : ""}
            </Dropdown.Menu>
          </Dropdown>
          {currentTable ? (
            <span className="currenttable">
              Current Table: <span>{currentTable.TableName}</span>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="mt-5">
        <button className="save-btn" onClick={() => handleHallTableChange()}>
          Save
        </button>
      </div>
    </Modal>
  );
};
