import React from "react";
import "../../assets/styles/passwordreset.css";
import logo from "../../assets/images/logo.png";

export const PasswordReset = () => {
  return (
    <div className="login-wrapper">
      <div className="login-box">
        <div className="logo-wrapper">
          <img src={logo} alt="logo" />
        </div>
        <p className="forget-title">
          Enter your e-mail to send a reset password link to you
        </p>
        <div className="login-form-wrapper">
          <form className="form">
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                className="form-control"
                name="email"
                type="text"
                placeholder="Enter your e-mail"
              />
            </div>

            <button className="reset-btn">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};
