import React from "react";
import BackspaceIcon from "@mui/icons-material/Backspace";
import SearchIcon from "@mui/icons-material/Search";

export const Calculator = ({
  handleQuantityOrSearchTermChange,
  handleDeleteNumber,
  searchForOrder,
  searchTerm,
}) => {
  return (
    <div className="calculator-wrapper">
      <div className="calculator-container">
        <div className="buttons-row">
          <span
            className="calc-btn"
            data-number="1"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            1
          </span>
          <span
            className="calc-btn"
            data-number="2"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            2
          </span>
          <span
            className="calc-btn"
            data-number="3"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            3
          </span>
        </div>
        <div className="buttons-row">
          <span
            className="calc-btn"
            data-number="4"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            4
          </span>
          <span
            className="calc-btn"
            data-number="5"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            5
          </span>
          <span
            className="calc-btn"
            data-number="6"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            6
          </span>
        </div>
        <div className="buttons-row">
          <span
            className="calc-btn"
            data-number="7"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            7
          </span>
          <span
            className="calc-btn"
            data-number="8"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            8
          </span>
          <span
            className="calc-btn"
            data-number="9"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(e.target.dataset.number)
            }
          >
            9
          </span>
        </div>
        <div className="buttons-row">
          <span
            className="calc-btn"
            data-number="0"
            onClick={(e) =>
              handleQuantityOrSearchTermChange(
                parseInt(e.target.dataset.number)
              )
            }
          >
            0
          </span>

          <span
            className="calc-btn"
            onClick={handleDeleteNumber}
            style={{ width: "fit-content" }}
          >
            <BackspaceIcon
              style={{ cursor: "pointer", fontSize: "3rem", width: "100%" }}
            />
          </span>

          <span
            className="calc-btn abc"
            style={{ width: "fit-content" }}
            onClick={() => {
              searchForOrder(searchTerm);
            }}
          >
            <SearchIcon style={{ cursor: "pointer", fontSize: "3rem" }} />
          </span>
          {/* <button
            className="calc-btn abc"
            style={{ width: "fit-content", fontSize: "1rem" }}
          >
            ABC
          </button> */}
        </div>
      </div>
    </div>
  );
};
