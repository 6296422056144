import React, { Fragment, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { AddArea } from "../components/AddArea";
import { Login } from "../components/auth/Login";
import { PasswordReset } from "../components/auth/PasswordReset";
import { CaptainOrderArea } from "../components/CaptainOrderArea";
import { Cashier } from "../components/cashier/Cashier";
import { Orders } from "../components/cashier/Orders";
// import { ListItems } from "../components/ListItems";
import "../assets/styles/all.css";
import { Kitchen } from "../components/Kitchen";
// import { AddItems } from "../components/AddItems";
import MapProducts from "../components/mapping/MapProducts";
import AuthRequired from "../components/auth/AuthRequired";
import Guest from "../components/auth/Guest";
import App from "../components/App";
import {
  UserSettingsContext,
  UserSettingsProvider,
} from "../context/UserSettingsContext";

export const AppRoutes = () => {
  // const userToken = sessionStorage.getItem("token");
  const [userSettings, setUserSettings] = useState(null);

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          {/* <Route
            path="/"
            exact
            element={
              userToken ? <Navigate to="/cashier" /> : <Navigate to="/login" />
            }
          /> */}
          {/* <Route path="/add-area" element={<AddArea />} /> */}
          {/* <Route path="/list-items" element={<ListItems />} /> */}
          {/* <Route path="/add-items" element={<AddItems />} /> */}
          <Route path="/captainorder" element={<CaptainOrderArea />} />
          <Route path="/kitchen" element={<Kitchen />} />
          <Route path="/orders" element={<Orders />} />
          <Route element={<Guest />}>
            <Route path="/" element={<App />} />
            <Route
              path="/login"
              element={
                <UserSettingsContext.Provider
                  value={{ userSettings, setUserSettings }}
                >
                  <Login />
                </UserSettingsContext.Provider>
              }
            />
          </Route>
          <Route element={<AuthRequired />}>
            <Route
              path="/cashier"
              element={
                <UserSettingsContext.Provider
                  value={{ userSettings, setUserSettings }}
                >
                  <Cashier />
                </UserSettingsContext.Provider>
              }
            />
            <Route path="/map-products" element={<MapProducts />} />
          </Route>
          <Route path="/reset-your-password" element={<PasswordReset />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};
