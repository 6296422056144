import { useState, createContext } from "react";

export const UserSettingsContext = createContext();

// export const UserSettingsProvider = ({ children }) => {
//   const [userSettings, setUserSettings] = useState({});

//   const updateUserSettings = (settings) => {
//     setUserSettings(settings);
//   };

//   console.log(userSettings);

//   return (
//     <UserSettingsContext.Provider value={{ userSettings, updateUserSettings }}>
//       {children}
//     </UserSettingsContext.Provider>
//   );
// };
