import axios from "axios";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

const axiosAuthInstance = axios.create({
  // ezdan link
  baseURL: "https://arresturanttestapi.smartvillageqatar.com/",

  // restui link
  // baseURL: "https://restaurantapitest.smartvillageqatar.com/",

  // production link
  // baseURL: "https://mrbuffaloapi.smartvillageqatar.com/",

  Accept: "application/json",
  "Content-Type": "application/json",
});

const decryptWithAES = (text, passphrase) => {
  const decryptedToken = AES.decrypt(text, passphrase);
  return decryptedToken.toString(Utf8);
};

axiosAuthInstance.interceptors.request.use(function (config) {
  const token = decryptWithAES(sessionStorage.getItem("ut"), "userToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default axiosAuthInstance;
