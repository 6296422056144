import React, { useEffect } from "react";
import "../../assets/styles/takeaway.css";

export const Takeaway = ({ toggleTakeaway, showTakeaway }) => {
  const toggleShowTakeaway = () => {
    document.querySelector(".ta-container").classList.toggle("show");
    setTimeout(() => {
      toggleTakeaway();
    }, 300);
  };
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".ta-container").classList.add("show");
    }, 0);
  }, []);
  return (
    <div style={{}}>
      <div
        className="ta-wrapper"
        onClick={() => {
          toggleShowTakeaway();
        }}
      ></div>
      <div className={`ta-container`} onClick={(e) => e.stopPropagation()}>
        <table className="ta-table">
          <thead>
            <tr>
              <th className="">Order-No</th>
              <th className="ta-ordertitle">Order</th>
              <th className="">Time</th>
              <th className="">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="">1</td>
              <td className="ta-itemname">
                Barbecue , Salad , Soap , Sweet Kebaba
              </td>
              <td className="">1:30pm</td>
              <td className="">
                <button>Done</button>
              </td>
            </tr>
            <tr>
              <td className="">2</td>
              <td className="ta-itemname">
                Barbecue , Salad , Soap , Sweet Kebaba
              </td>
              <td className="">1:30pm</td>
              <td className="">
                <button>Done</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
