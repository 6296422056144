import { Draggable } from "react-beautiful-dnd";
import Item from "./Item";

const Product = ({ item, innerRef, index, columnTitle }) => {
  return (
    <Draggable
      key={
        item && item.PK_Item_ID
          ? `${columnTitle}.${item.PK_Item_ID.toString()}`
          : `${columnTitle}.${item.id.toString()}`
      }
      draggableId={item.identifier.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <Item
          item={item}
          index={index}
          innerRef={provided.innerRef}
          columnTitle={columnTitle}
          provided={provided}
          isDragging={snapshot.isDragging}
        />
      )}
    </Draggable>
  );
};

export default Product;
