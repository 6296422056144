import { useEffect, useRef, useReducer } from "react";
import axiosAuthInstance from "../api/axiosAuthInstance";

export const useApi = (api) => {
  const cachedData = useRef({});
  const initialState = {
    status: "idl",
    data: null,
    error: null,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };

      case "FETCHED":
        return { ...initialState, status: "fetched", data: action.payload };

      case "FETCHED_ERRROR":
        return { ...initialState, status: "error", data: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let revokeRequest = false;
    if (!api || !api.trim()) return;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });

      try {
        if (cachedData.current[api]) {
          let data = cachedData.current[api];
          dispatch({ type: "FETCHED", payload: data });
        } else {
          const response = await axiosAuthInstance.get(api);
          cachedData.current[api] = response.data;
          dispatch({ type: "FETCHED", payload: cachedData.current[api] });
        }
      } catch (error) {
        if (revokeRequest) return;

        dispatch({ type: "FETCHED_ERRROR", payload: error.message });
      }
    };

    fetchData();

    return function cleanup() {
      revokeRequest = true;
    };
  }, [api]);

  return state;
};
