import { Outlet, Navigate, useLocation } from "react-router-dom";
import React from "react";

const AuthRequired = () => {
  const location = useLocation();
  const token = sessionStorage.getItem("ut");
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default AuthRequired;
