import React, { useState, useEffect } from "react";
import "../../assets/styles/productsMapping.css";
import waiterfillAxios from "../../api/waiterfillAxios";
import { ToastContainer, toast } from "react-toastify";
import axiosAuthInstance from "../../api/axiosAuthInstance";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";
import Swal from "sweetalert2";
import { Modal } from "../cashier/Modal";

export const MappingModal = ({
  closeModal,
  customStyle,
  setMappingProductsCount,
}) => {
  const [columns, setColumns] = useState([
    { id: "sv", title: "SV Items", items: [] },
    { id: "matchingItems", title: "Matching Area", items: [] },
    { id: "finedine", title: "FineDine Items", items: [] },
  ]);

  const fetchSvItems = async () => {
    try {
      const svItemsIdsResponse = await waiterfillAxios.get("/getSvItemIds");

      const items = await axiosAuthInstance.get(
        `/Cashier/GetItemsNotInList?ItemsIDList=${svItemsIdsResponse.data}`
      );

      const mappedSvItems = items.data.map((item) => {
        item["identifier"] = `sv.${item.PK_Item_ID}`;
        return item;
      });

      const unmatchedPriceItemsResponse = await waiterfillAxios.get(
        "/unmatchedPriceItems"
      );

      let mappedNewUnmatchedPriceItems = Array.from(
        unmatchedPriceItemsResponse.data.unmatchedPriceItems
      );
      mappedNewUnmatchedPriceItems.map(
        (item) => (item["identifier"] = `finedine.${item.id}`)
      );

      setColumns([
        { id: "sv", title: "SV Items", items: mappedSvItems },
        { id: "matchingItems", title: "Matching Area", items: [] },
        {
          id: "finedine",
          title: "FineDine Items",
          items: mappedNewUnmatchedPriceItems,
        },
      ]);
    } catch (error) {
      toast.error(error);
    }
  };

  const onDragUpdate = (update) => {};

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    )
      return;

    let newSvItems = Array.from(columns[0].items);
    let newUnmatchedPriceItems = Array.from(columns[2].items);
    const matchingColumn = columns.find((item) => item.id === "matchingItems");

    if (
      matchingColumn.items.length === 1 &&
      matchingColumn.items[0].identifier.startsWith(source.droppableId)
    ) {
      Swal.fire({
        title: "Error!",
        text: "Can't match 2 items from the same column",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId.startsWith("sv")) {
        const target = newSvItems.find(
          (item) => item.identifier === draggableId
        );
        newSvItems.splice(source.index, 1);
        newSvItems.splice(destination.index, 0, target);

        const newColumns = columns.map((item) => {
          if (item["id"] === "sv") {
            item["items"] = newSvItems;
          }
          return item;
        });

        setColumns(newColumns);
      } else {
        const target = newUnmatchedPriceItems.find(
          (item) => item.identifier === draggableId
        );

        newUnmatchedPriceItems.splice(source.index, 1);
        newUnmatchedPriceItems.splice(destination.index, 0, target);
        const newFineDineColumns = columns.map((item) => {
          if (item["id"] === "finedine") {
            item["items"] = newUnmatchedPriceItems;
          }
          return item;
        });
        setColumns(newFineDineColumns);
      }
    } else {
      const start = columns.find((item) => item.id === source.droppableId);
      const finish = columns.find(
        (item) => item.id === destination.droppableId
      );
      const startItems = Array.from(start.items);
      startItems.splice(source.index, 1);
      const newStart = { ...start, items: startItems };

      const target =
        start.id === "sv"
          ? columns[0].items.find((item) => item.identifier === draggableId)
          : start.id === "finedine"
          ? columns[2].items.find((item) => item.identifier === draggableId)
          : columns[1].items.find((item) => item.identifier === draggableId);

      // console.log(target);

      const finishItems = Array.from(finish.items);
      finishItems.splice(destination.index, 0, target);
      const newFinish = { ...finish, items: finishItems };
      console.log(newStart, newFinish);
      const newColumns = columns.map((column) => {
        if (column.id === newStart.id) {
          column.items = newStart.items;
        } else if (column.id === newFinish.id) {
          column.items = newFinish.items;
        }
        return column;
      });

      setColumns(newColumns);
    }
  };

  useEffect(() => {
    if (columns[1].items.length === 2) {
      const { svProductToMatch, finedineProductToMatch } = getItemsToMatch();
      if (!svProductToMatch || !finedineProductToMatch) {
        Swal.fire({
          title: "Error!",
          text: "Can't match 2 items from the same column",
          icon: "error",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result["isConfirmed"] || result["isDenied"]) {
            returnColumnsToDefaultState();
            return;
          }
        });

        return;
      }

      const matchingColumn = columns.find(
        (item) => item.id === "matchingItems"
      );
      const finedineProduct = matchingColumn.items.find(
        (item) => item.svItemId === null
      );
      const svProduct = matchingColumn.items.find((item) =>
        item.identifier.startsWith("sv")
      );
      const swalMessageText = `Item: <strong style="color:red">${svProduct.ItemName}</strong> will be matched to item: <strong style="color:red">${finedineProduct.name}</strong>, <strong>Are you sure?</strong>`;

      Swal.fire({
        html: swalMessageText,
        title: "Success!",
        text: swalMessageText,
        icon: "success",
        confirmButtonText: "Ok",
        showCancelButton: true,
        cancelButtonText: "cancel",
      }).then(async (result) => {
        if (result["isConfirmed"]) {
          const matchingResponse = await waiterfillAxios.post("match_items", {
            svProduct: svProduct,
            finedineProduct: finedineProduct,
          });

          const { message } = matchingResponse.data;
          console.log(!message);
          if (!message) {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong, please try again later",
              icon: "error",
              confirmButtonText: "Ok",
            }).then((result) => {
              returnColumnsToDefaultState();
              return;
            });
          } else {
            Swal.fire({
              title: "Success!",
              text: "Success Matching",
              icon: "success",
              confirmButtonText: "Ok",
            });

            setMappingProductsCount((prevValue) => prevValue - 1);
            const updatedColumns = columns.map((item) => {
              if (item.id === "matchingItems") {
                item.items = [];
              }
              return item;
            });

            setColumns(updatedColumns);
          }
        }

        if (result["isDismissed"] || result["isDenied"]) {
          returnColumnsToDefaultState();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const getItemsToMatch = () => {
    const matchingColumn = columns.find((item) => item.id === "matchingItems");
    const finedineProductToMatch = matchingColumn.items.find(
      (item) => item.svItemId === null
    );
    const svProductToMatch = matchingColumn.items.find((item) =>
      item.identifier.startsWith("sv")
    );

    return {
      matchingColumn,
      svProductToMatch,
      finedineProductToMatch,
    };
  };

  // const updateColumnsAfterMatching = () => {
  //   const matchingColumn = columns.find((item) => item.id === "matchingItems");
  //   let finedineProductToMatch = matchingColumn.items.find(
  //     (item) => item.svItemId === null
  //   );
  //   let svProductToMatch = matchingColumn.items.find((item) =>
  //     item.identifier.startsWith("sv")
  //   );

  //   const mappedColumns = columns.map((item) => {
  //     if (item.id === "sv") {
  //       item.items.filter(item => item.pkItemId !== svProductToMatch.pkItemId);
  //     }
  //     if (item.id === "finedine") {
  //       item.items.filter(finedineProductToMatch);
  //     }
  //     if (item.id === "matchingItems") {
  //       item.items = [];
  //     }

  //     return item;
  //   });

  //   mappedColumns.forEach((item) => {
  //     item.items.sort((a, b) => (a.id ? a.id - b.id : a.pkItemId - b.pkItemId));
  //   });

  //   setColumns(mappedColumns);
  // };

  const returnColumnsToDefaultState = () => {
    const matchingColumn = columns.find((item) => item.id === "matchingItems");
    let finedineProductToMatch = matchingColumn.items.find(
      (item) => item.svItemId === null
    );
    let svProductToMatch = matchingColumn.items.find((item) =>
      item.identifier.startsWith("sv")
    );

    const mappedColumns = columns.map((item) => {
      if (item.id === "sv") {
        item.items.push(svProductToMatch);
      }
      if (item.id === "finedine") {
        item.items.push(finedineProductToMatch);
      }
      if (item.id === "matchingItems") {
        item.items = [];
      }

      return item;
    });

    mappedColumns.forEach((item) => {
      item.items.sort((a, b) =>
        a.id ? a.id - b.id : a.PK_Item_ID - b.PK_Item_ID
      );
    });

    setColumns(mappedColumns);
  };

  useEffect(() => {
    fetchSvItems();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal closeModal={closeModal} customStyle={customStyle}>
      {/* provided.innerRef is a function used to supply the dom node of the
      droppable element to react dnd */}
      <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
        <div className="productsMappingWrapper">
          {columns.map((item) => (
            <Droppable droppableId={item.id} key={item.id}>
              {(provided, snapshot) => (
                <Column
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                  columnTitle={item.title}
                  products={item["items"]}
                  provided={provided}
                  draggingOver={snapshot.isDraggingOver}
                ></Column>
              )}
            </Droppable>
          ))}
        </div>
        <ToastContainer />
      </DragDropContext>
    </Modal>
  );
};
