const Item = ({ item, innerRef, provided, isDragging }) => {
  return (
    <div
      className={isDragging ? "item dragging" : "item"}
      ref={innerRef}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
    >
      <div className="imageWrapper">
        <img src={item.image ?? item.itemImage} alt="" />
      </div>
      <div className="itemDataWrapper">
        <p>
          <span>id :</span> {item.PK_Item_ID ?? item.id}
        </p>
        <p>
          <span>name: </span>
          {item.ItemName ?? item.name}
        </p>
        <p>
          <span>price: </span>
          {item.price ?? item.Price}
        </p>
      </div>
    </div>
  );
};

export default Item;
