import React from "react";
import "../../assets/styles/orders.css";

export const Orders = () => {
  return (
    <div className="orders-wrapper">
      <div className="orders-container">
        <div className="order-wrapper">
          <div className="order-wrapper-title">
            <p className="title">
              <span className="ordernumber">Order -1-</span>
            </p>
            <p className="title">
              <span className="orderroom">RoomA</span>
              <span className="">-</span>
              <span className="ordertable">T10</span>
            </p>
            <p className="title">00:02:00</p>
          </div>
          <div className="order-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="">Item Name</th>
                  <th className="">Notes / Add on</th>
                  <th className="">Price / Unit</th>
                  <th className="">Total Price</th>
                  <th className="">Status</th>
                  <th className="">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="itemname">
                    Barbeque beef with rice and Barbeque beef with rice
                  </td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="done">Done</span>
                  </td>
                  <td className="">1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="order-btns-wrapper">
            <button className="payprintbtn">Pay & Print</button>
            <button className="printbtn">Print Only</button>
          </div>
        </div>
        <div className="order-wrapper">
          <div className="order-wrapper-title">
            <p className="title">
              <span className="ordernumber">Order -1-</span>
            </p>
            <p className="title">
              <span className="orderroom">RoomA</span>
              <span className="">-</span>
              <span className="ordertable">T10</span>
            </p>
            <p className="title">00:02:00</p>
          </div>
          <div className="order-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="">Item Name</th>
                  <th className="">Notes / Add on</th>
                  <th className="">Price / Unit</th>
                  <th className="">Total Price</th>
                  <th className="">Status</th>
                  <th className="">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="itemname">
                    Barbeque beef with rice and Barbeque beef with rice
                  </td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="delay">Limited Delay</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="done">Done</span>
                  </td>
                  <td className="">1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="order-btns-wrapper">
            <button className="payprintbtn">Pay & Print</button>
            <button className="printbtn">Print Only</button>
          </div>
        </div>
        <div className="order-wrapper">
          <div className="order-wrapper-title">
            <p className="title">
              <span className="ordernumber">Order -1-</span>
            </p>
            <p className="title">
              <span className="orderroom">RoomA</span>
              <span className="">-</span>
              <span className="ordertable">T10</span>
            </p>
            <p className="title">00:02:00</p>
          </div>
          <div className="order-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="">Item Name</th>
                  <th className="">Notes / Add on</th>
                  <th className="">Price / Unit</th>
                  <th className="">Total Price</th>
                  <th className="">Status</th>
                  <th className="">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="itemname">
                    Barbeque beef with rice and Barbeque beef with rice
                  </td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="done">Done</span>
                  </td>
                  <td className="">1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="order-btns-wrapper">
            <button className="payprintbtn">Pay & Print</button>
            <button className="printbtn">Print Only</button>
          </div>
        </div>
        <div className="order-wrapper">
          <div className="order-wrapper-title">
            <p className="title">
              <span className="ordernumber">Order -1-</span>
            </p>
            <p className="title">
              <span className="orderroom">RoomA</span>
              <span className="">-</span>
              <span className="ordertable">T10</span>
            </p>
            <p className="title">00:02:00</p>
          </div>
          <div className="order-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="">Item Name</th>
                  <th className="">Notes / Add on</th>
                  <th className="">Price / Unit</th>
                  <th className="">Total Price</th>
                  <th className="">Status</th>
                  <th className="">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="itemname">
                    Barbeque beef with rice and Barbeque beef with rice
                  </td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="delay">Limited Delay</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="done">Done</span>
                  </td>
                  <td className="">1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="order-btns-wrapper">
            <button className="payprintbtn">Pay & Print</button>
            <button className="printbtn">Print Only</button>
          </div>
        </div>
        <div className="order-wrapper">
          <div className="order-wrapper-title">
            <p className="title">
              <span className="ordernumber">Order -1-</span>
            </p>
            <p className="title">
              <span className="orderroom">RoomA</span>
              <span className="">-</span>
              <span className="ordertable">T10</span>
            </p>
            <p className="title">00:02:00</p>
          </div>
          <div className="order-table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="">Item Name</th>
                  <th className="">Notes / Add on</th>
                  <th className="">Price / Unit</th>
                  <th className="">Total Price</th>
                  <th className="">Status</th>
                  <th className="">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="itemname">
                    Barbeque beef with rice and Barbeque beef with rice
                  </td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="inprogress">In progress</span>
                  </td>
                  <td className="">1</td>
                </tr>
                <tr>
                  <td className="">Barbeque beef with rice</td>
                  <td className="">Less sauce</td>
                  <td className="">Parmesan</td>
                  <td className="">1</td>
                  <td className="">
                    <span className="done">Done</span>
                  </td>
                  <td className="">1</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="order-btns-wrapper">
            <button className="payprintbtn">Pay & Print</button>
            <button className="printbtn">Print Only</button>
          </div>
        </div>
      </div>
    </div>
  );
};
