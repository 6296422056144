import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "../../assets/styles/modal.css";

const Overlay = ({ title, closeModal, children, customStyle }) => {
  return (
    <Fragment>
      <div
        className="modal-overlay"
        onClick={(e) => {
          closeModal(e);
        }}
      ></div>
      <div
        className="custommodal-content"
        style={customStyle}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </Fragment>
  );
};

export const Modal = ({ closeModal, children, customStyle }) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Overlay
          title="modal body"
          children={children}
          closeModal={closeModal}
          customStyle={customStyle}
        />,
        document.getElementById("overlay-root")
      )}
    </Fragment>
  );
};
