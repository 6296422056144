import React, { useEffect } from "react";
import { Modal } from "./Modal";

export const PaymentModal = ({
  closeModal,
  customStyle,
  subtotal,
  oldSubTotal,
  orderMasterPaymentMethod,
  handleDiscountChange,
  currentSelectedOption,
  discountsList,
  discountReason,
  setdiscountReason,
  discountPercentage,
  discountValue,
  discountedTotal,
  paymentMethod,
  setPaymentMethod,
  renderMixPaymentInputs,
  showPaymentModal,
  handlePaymentDone,
  setShowPaymentModal,
  // setCashPaidAmount,
  // setVisaPaidAmount,
  // setDiscountedTotal,
  companies,
  setPaymentCompany,
}) => {
  useEffect(() => {
    setPaymentMethod("cash");
    // setCashPaidAmount(discountedTotal);
    // setDiscountedTotal(discountedTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotal]);

  const renderPaymentMethods = (orderMasterPaymentMethod) => {
    return (
      <div className="methodswrapper">
        <div className="paymentmethod">
          <input
            type="radio"
            id="cash"
            name="paymentmethod"
            checked={paymentMethod === "cash"}
            onChange={(e) => {
              setPaymentMethod("cash");
            }}
          />
          <label htmlFor="cash">Cash</label>
        </div>
        <div className="paymentmethod">
          <input
            type="radio"
            id="visa"
            name="paymentmethod"
            checked={paymentMethod === "visa"}
            onChange={(e) => {
              setPaymentMethod("visa");
            }}
          />
          <label htmlFor="visa">Visa</label>
        </div>
        <div className="paymentmethod">
          <input
            type="radio"
            id="mix"
            name="paymentmethod"
            checked={paymentMethod === "mix"}
            onChange={(e) => {
              setPaymentMethod("mix");
            }}
          />
          <label htmlFor="mix">Mix</label>
        </div>
        <div className="paymentmethod">
          <input
            type="radio"
            id="company"
            name="paymentmethod"
            checked={paymentMethod === "company"}
            onChange={(e) => {
              setPaymentMethod("company");
            }}
          />
          <label htmlFor="company">Company</label>
        </div>
      </div>
    );
  };
  return (
    <Modal closeModal={closeModal} customStyle={customStyle}>
      <div className="payment-methods-wrapper">
        <p>Total: {subtotal}</p>

        <div className="row" style={{ marginBottom: "1rem" }}>
          <div className="col-md-4">
            <label htmlFor="discount">Dicsount</label>
            <select
              name="discount"
              onChange={(e) => handleDiscountChange(e)}
              className="form-control"
              id="discount"
              defaultValue={currentSelectedOption}
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                color: "black",
              }}
            >
              {discountsList
                ? discountsList.map((item, index) => (
                    <option
                      disabled={item.pkDiscountId === 0}
                      // selected={item.pkDiscountId === 0}
                      key={index}
                      value={item.discountPercentage}
                    >
                      {item.discountPercentage}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <div className="col-md-8">
            <label htmlFor="discount">Dicsount Reason</label>
            <input
              type="text"
              className="form-control"
              name="discountReason"
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                color: "black",
              }}
              value={discountReason}
              onChange={(e) => {
                setdiscountReason(e.target.value);
              }}
            />
          </div>
        </div>
        <p>Discount Value: {discountPercentage ? discountValue : null}</p>
        <p>Total After Discount: {discountedTotal}</p>
        {companies ? (
          <div className="col-md-4 mb-3">
            <p>Companies</p>
            <select
              name="company"
              id="company"
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                color: "black",
              }}
              className="form-control"
              onChange={(e) => setPaymentCompany(parseInt(e.target.value))}
            >
              {companies
                ? companies.map((item, index) => (
                    <option key={index} value={item.pkCompanyId}>
                      {item.companyName}
                    </option>
                  ))
                : ""}
            </select>
          </div>
        ) : (
          ""
        )}

        <strong>Choose Payment Method</strong>

        {/* {renderPaymentMethods(orderMasterPaymentMethod)} */}
        {renderPaymentMethods()}

        {paymentMethod === "mix" ? renderMixPaymentInputs() : ""}
        <div className="row paymentModalBtnsWrapper">
          <div className="col-md-6">
            <button
              onClick={() => {
                handlePaymentDone();
              }}
            >
              Ok
            </button>
          </div>
          <div className="col-md-6">
            <button
              onClick={(e) => {
                setShowPaymentModal(!showPaymentModal);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
