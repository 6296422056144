import axios from "axios";

export default axios.create({
  // baseURL: "https://productionwaiterfill.scheduler.smartqr.app/api/",

  // restui link
  // baseURL: "https://waiterfill.scheduler.smartqr.app/api/",

  // production link
  baseURL: "https://productionwaiterfill.scheduler.smartqr.app/api/",
});
